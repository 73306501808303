<template>
  <sm-categories-lists
    :breadcrumbs="breadcrumbs"
    :categories-group-name="categoriesGroupName"
    :list-name="categoriesGroupName"
  />
</template>

<script>
// components
import SmCategoriesLists from '@/components/views/SmCategoriesLists.vue';

export default {
  name: 'CustomersMain',

  components: {
    SmCategoriesLists,
  },

  data() {
    return {
      breadcrumbs: [{ text: 'Клиенты' }],

      categoriesGroupName: 'customers',
      categoriesGroup: [
        {
          // title: 'Общие',
          list: [
            {
              name: 'Клиенты',
              route: { name: 'Customers' },
              controllerName: 'Customers',
            },
            {
              name: 'Сайты клиентов',
              route: { name: 'Sites' },
              controllerName: 'Sites',
            },
            {
              name: 'Создание договоров',
              route: { name: 'ContractCreate' },
              controllerName: 'OneCContracts',
            },
						{
              name: 'Клиенты, по которым не контролировать долги',
              route: { name: 'IgnoredCustomers' },
              controllerName: 'IgnoredCustomers',
            },
          ],
        },
      ],
    };
  },
};
</script>
